<template>
  <v-container fluid class="ma-0 pa-0" :class="{'light-background': $vuetify.breakpoint.xsOnly, 'white': $vuetify.breakpoint.smAndUp}">
    <v-layout row wrap :pa-5="$vuetify.breakpoint.smAndUp">
      <v-flex xs12 class="max-width-1400 full-width ma-auto px-2">
        <v-layout row wrap>
          <v-flex xs12 pa-3 text-xs-center>
            <p class="graphik-medium font-25 mb-4 dark-grey--text">{{ $ml.get('login_register') }}</p>
          </v-flex>
        </v-layout>

        <v-layout row wrap>
          <v-flex xs12 offset-sm2 sm8 offset-md3 md6 pa-3 text-xs-center>
            <v-card color="light-background" flat tile>
              <v-card-text class="px-4">
                <v-form v-model="valid_user">
                  <v-layout row wrap>
                    <v-flex xs12>
                      <p class="graphik-medium font-17 mt-3 mb-2 dark-grey--text text-xs-left">{{ $ml.get('fields_name') }}</p>

                      <v-text-field
                        v-model="user.first_name"
                        solo
                        clearable
                        required
                        :disabled="g_isLoading"
                        :rules="g_required_rules"
                        class="hidden-shadow small-input"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12>
                      <p class="graphik-medium font-17 mb-2 dark-grey--text text-xs-left">{{ $ml.get('fields_last_name') }}</p>

                      <v-text-field
                        v-model="user.last_name"
                        solo
                        clearable
                        required
                        :disabled="g_isLoading"
                        :rules="g_required_rules"
                        class="hidden-shadow small-input"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12>
                      <p class="graphik-medium font-17 mb-2 dark-grey--text text-xs-left">{{ $ml.get('fields_second_last_name') }}</p>

                      <v-text-field
                        v-model="user.second_last_name"
                        solo
                        clearable
                        required
                        :disabled="g_isLoading"
                        :rules="g_required_rules"
                        class="hidden-shadow small-input"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12>
                      <p class="graphik-medium font-17 mb-2 dark-grey--text text-xs-left">{{ $ml.get('fields_cellphone') }}</p>

                      <v-text-field
                        v-model="user.cellphone"
                        solo
                        clearable
                        required
                        :disabled="g_isLoading"
                        mask="phone"
                        :rules="g_phone_rules"
                        class="hidden-shadow small-input"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12>
                      <p class="graphik-medium font-17 mb-2 dark-grey--text text-xs-left">{{ $ml.get('fields_gender') }}</p>

                      <v-autocomplete
                        v-model="user.gender"
                        :items="options.genders"
                        solo
                        clearable
                        :disabled="g_isLoading"
                        required
                        :rules="g_required_rules"
                        :no-data-text="$ml.get('general_no_results')"
                        class="hidden-shadow small-input"
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12>
                      <p class="graphik-medium font-17 mb-2 dark-grey--text text-xs-left">{{ $ml.get('fields_birthday') }}</p>

                      <v-menu
                        :close-on-content-click="false"
                        v-model="birthdate_menu"
                        :nudge-right="40"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        max-width="290px"
                        min-width="290px"
                      >
                        <v-text-field
                          solo
                          slot="activator"
                          v-model="birthdate_formatted"
                          persistent-hint
                          readonly
                          append-icon="event"
                          required
                          :disabled="g_isLoading"
                          :rules="g_required_rules"
                          class="hidden-shadow small-input"
                        ></v-text-field>

                        <v-date-picker
                          ref="birthdate_picker"
                          v-model="user.birthday"
                          no-title
                          locale="es-ES"
                          :max="adult_only"
                          min="1900-01-01"
                          @input="birthdate_menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12>
                      <p class="graphik-medium text-xs-left font-17 mb-2 dark-grey--text">{{ $ml.get('fields_email') }}</p>

                      <v-text-field
                        v-model="user.email"
                        solo
                        clearable
                        required
                        :disabled="g_isLoading"
                        :rules="g_email_rules"
                        class="hidden-shadow small-input"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12>
                      <p class="graphik-medium text-xs-left font-17 mb-2 dark-grey--text">{{ $ml.get('fields_password') }}</p>

                      <v-text-field
                        v-model="user.password"
                        solo
                        clearable
                        required
                        :disabled="g_isLoading"
                        :rules="g_password_rules"
                        class="hidden-shadow small-input"
                        :type="hidePassword ? 'password' : 'text'"
                        :append-icon="hidePassword ? 'visibility' : 'visibility_off'"
                        @click:append="() => (hidePassword = !hidePassword)"
                      ></v-text-field>
                    </v-flex>


                    <v-flex xs12 pt-4>
                      <p class="graphik-medium text-xs-left font-15 mb-2 dark-grey--text text-xs-center">{{ $ml.get('login_accept_terms') }}<br><span class="primary--text graphik-bold-italic clickable" @click="$goTo('/terminosycondiciones', 'goto_terms')">{{ $ml.get('footer_title_terms') }}</span></p>
                    </v-flex>

                    <v-flex xs12>
                      <v-btn 
                        color="primary"
                        depressed dark
                        :loading="g_isLoading"
                        class="normalcase graphik-bold-italic ma-0 border-radius-5"
                        @click="createAccount"
                      >{{ $ml.get('login_create_account') }}</v-btn>
                    </v-flex>

                    <v-flex xs12 d-flex align-center py-5>
                      <v-divider class="grey"></v-divider>
                      
                      <p class="graphik-medium text-xs-center font-17 ma-0 dark-grey--text max-width-150 lowercase">{{ $ml.get('login_with_register') }}</p>

                      <v-divider class="grey"></v-divider>
                    </v-flex>

                    <v-flex xs12>
                      <v-btn
                        depressed block
                        color="blue darken-3"
                        class="normalcase white--text border-radius-5"
                        @click="loginSocial('facebook')"
                      ><v-icon small color="white" class="mr-3">fab fa-facebook-f</v-icon>{{ $ml.get('login_with_facebook') }}</v-btn>
                    </v-flex>

                    <v-flex xs12>
                      <v-btn
                        depressed block
                        color="red darken-3"
                        class="normalcase white--text border-radius-5"
                        @click="loginSocial('google')"
                      ><v-icon small color="white" class="mr-3">fab fa-google</v-icon>{{ $ml.get('login_with_google') }}</v-btn>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout> 
      </v-flex>
    </v-layout>

    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
  </v-container>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

let firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

export default {
  name: 'Registro',
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_REGISTER,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_REGISTER
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_REGISTER
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_REGISTER
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_REGISTER
      },
      {
        vmid: 'twitter:description',
        property: 'twitter:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_REGISTER
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: process.env.VUE_APP_METATAGS_URL + '/registro'
      },
    ],
    link: [
      // SEO
      {
        vmid: 'canonical',
        rel: 'canonical',
        href: process.env.VUE_APP_METATAGS_URL + '/registro'
      }
    ]
  },
  data () {
    return {
      valid_user: false,
      hidePassword: true,
      birthdate_menu: false,
      user: {
        first_name: '',
        last_name: '',
        second_last_name: '',
        cellphone: '',
        gender: '',
        birthday: null,
        email: '',
        password: '',
        policy_uuid: this.$route.params.policy

      },
      options: {
        genders: [
          {
            text: this.$ml.get('fields_options_gender_male'),
            value: 'H',
            icon: 'fas fa-male'
          },
          {
            text: this.$ml.get('fields_options_gender_female'),
            value: 'M',
            icon: 'fas fa-female'
          }
        ]
      }
    }
  },
  watch: {
    birthdate_menu (value) {
      value && this.$nextTick(() => (this.$refs.birthdate_picker.activePicker = 'YEAR'))
    }
  },
  computed: {
    birthdate_formatted: {
      get () {
        if (!this.user.birthday) return null

        const [year, month, day] = this.user.birthday.split('-')
        return `${day}/${month}/${year}`
      },
      set () {
        this.user.birthday = this.user.birthday
      }
    },
    adult_only () {
      return this.$moment().subtract(18, 'years').format('YYYY-MM-DD')
    }
  },
  methods: {
    createAccount () {
      this.g_isLoading = true

      this.$api.createUser(
        this.user,
        () => {
          this.$analytics('register_create_account')
          this.$auth(this.user, '/perfil')
        }, response => this.$errorHandling(response).then(() => {
          this.createAccount()
        }, () => {})
      )
    },
    createAccountSocial (source) {
      this.g_isLoading = true
      var provider = null
      if (source === 'google') provider = new firebase.auth.GoogleAuthProvider()
      if (source === 'facebook') provider = new firebase.auth.FacebookAuthProvider()

      firebase.auth().signInWithPopup(provider).then((result) => {
        let auth = {
          token: result.credential.accessToken,
          uid: result.user.providerData[0].uid
        }

        this.$api.authSocial(
          auth,
          source,
          response => {
            this.$store.dispatch('authenticate', {
              access_token: response.data.access_token,
              refresh_token: response.data.refresh_token
            })
            this.$goTo('/shipping', 'create_account_' + source)
          }, response => this.$errorHandling(response).then(() => {
            this.createAccountSocial(source)
          }, () => {})
        )
      }).catch((error) => {
        this.g_isLoading = false
        this.g_dialog = {
          show: true,
          title: this.$ml.get('general_dialog_error'),
          message: error.message
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
